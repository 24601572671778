<template>
  <div v-if="isShow" class="c-modal md">
    <div class="c-modal__overlay"></div>
    <div class="c-modal__content">
      <div class="c-modal__head">
        <h5 class="c-lead-s">カレンダー設定</h5>
        <span class="c-modal__head__close" @click="closeModalHandler"></span>
      </div>
      <div class="c-modal__body">
        <div class="c-inputWrap">
          <div class="c-inputWrap__label">
            <label>開始時刻</label>
          </div>
          <div class="c-inputWrap__items">
            <Selectbox name="start-hour" :value.sync="startHour">
              <option
                v-for="(_n, index) in 24"
                :key="'sh' + index"
                :value="index"
              >
                {{ index }}
              </option>
            </Selectbox>
          </div>
        </div>
        <div class="c-inputWrap">
          <div class="c-inputWrap__label">
            <label>表示単位</label>
          </div>
          <div class="c-inputWrap__items">
            <div class="c-inputWrap__items__cols">
              <div class="c-radio">
                <input
                  id="per-hald-min"
                  type="radio"
                  :value="false"
                  v-model="perHour"
                />
                <label class="c-radio__label" for="per-hald-min">
                  <span class="c-radio__box"></span>
                  30分
                </label>
              </div>
              <div class="c-radio">
                <input
                  id="per-hour"
                  type="radio"
                  :value="true"
                  v-model="perHour"
                />
                <label class="c-radio__label" for="per-hour">
                  <span class="c-radio__box"></span>
                  １時間
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="c-modal__foot">
        <div class="c-btn primary small" :class="{ disabled: isSame() }" @click="clickSetHandler">設定</div>
      </div>
    </div>
  </div>
</template>

<script>
import Selectbox from "@/components/parts/input/Selectbox";
import { COOKIE_INFO } from "@/const";

export default {
  components: {
    Selectbox,
  },
  props: {
    isShow: {
      type: Boolean,
    },
    startHourPos: {
      type: Number,
    },
    isPerHour: {
      type: Boolean,
    },
  },
  data() {
    return {
      startHour: this.startHourPos,
      perHour: this.isPerHour,
    };
  },
  watch: {
    startHourPos() {
      this.startHour = this.startHourPos;
    },
    isPerHour() {
      this.perHour = this.isPerHour;
    },
  },
  methods: {
    isSame() {
      const cookieStartHour = JSON.parse(this.$cookies.get(COOKIE_INFO.SHOW_HOUR));
      const cookiePerHour = JSON.parse(this.$cookies.get(COOKIE_INFO.PER_HOUR));
      return this.startHour === cookieStartHour && this.perHour === cookiePerHour;
    },
    closeModalHandler() {
      this.$emit("update:isShow", false);
    },
    clickSetHandler() {
      this.$cookies.set(COOKIE_INFO.SHOW_HOUR, this.startHour);
      this.$cookies.set(COOKIE_INFO.PER_HOUR, this.perHour);
      this.$router.go({ path: this.$router.currentRoute.path, force: true });
    },
  },
};
</script>
<style scoped lang="scss">
.padding {
  margin-top: 3rem;
}
.asterisk {
  margin-bottom: 1rem;
  font-size: 1rem;
  color: #aaaaaa;
}
.disabled {
  opacity: 0.75;
  color: #aaa;
}
</style>
