<template>
  <div class="c-modal md" v-show="isShow">
    <div class="c-modal__overlay"></div>
    <div class="c-modal__content" v-if="schedule">
      <div class="c-modal__head">
        <h5 class="c-lead-s">
          {{ schedule.wasteInfo.wasteName ? schedule.wasteInfo.wasteName.name : '' }}
        </h5>
        <span class="c-modal__head__close" @click="closeModalHandler"></span>
      </div>

      <div class="c-modal__body">
        <div class="c-modal__body__scroll">
          <div class="c-modal__body__each">
            <div class="l-box">
              <h3 class="c-lead icon">マニフェスト情報</h3>
            </div>
            <div class="c-inputWrap" v-if="isCollector">
              <div class="c-inputWrap__label">
                <label>排出業者</label>
              </div>
              <div class="c-inputWrap__items">
                {{ schedule.manifestInfo.generateCompany ? schedule.manifestInfo.generateCompany.name : "-" }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>引き渡し日</label>
              </div>
              <div class="c-inputWrap__items">
                {{ schedule.manifestInfo.collectionDate }}
                {{ schedule.manifestInfo.collectionTime }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>登録担当者</label>
              </div>
              <div class="c-inputWrap__items">
                {{ schedule.manifestInfo.registMember ? schedule.manifestInfo.registMember.name : "-" }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>引き渡し担当者</label>
              </div>
              <div class="c-inputWrap__items">
                {{ schedule.manifestInfo.generateMember ? schedule.manifestInfo.generateMember.name : "-" }}
              </div>
            </div>
            <div v-if="schedule.manifestInfo.proxyCollectMember" class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>運搬代理登録担当者</label>
              </div>
              <div class="c-inputWrap__items">
                {{ schedule.manifestInfo.proxyCollectMember.name }}
              </div>
            </div>
            <div v-else-if="schedule.manifestInfo.proxyDisposalMember" class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>処分代理登録担当者</label>
              </div>
              <div class="c-inputWrap__items">
                {{ schedule.manifestInfo.proxyDisposalMember.name }}
              </div>
            </div>
          </div>

          <div class="c-modal__body__each">
            <div class="l-box">
              <h3 class="c-lead icon">廃棄物情報</h3>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>廃棄物種類</label>
              </div>
              <div class="c-inputWrap__items">
                {{ schedule.routeInfo.jwnetItemType.name }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>ルート</label>
              </div>
              <div class="c-inputWrap__items">
                {{ schedule.routeInfo.routeName }}
                -
                {{ schedule.routeInfo.generateStore.name }}
                -
                {{ schedule.routeInfo.collectCompanys.map(c => c.name).join(",") }}
                -
                {{ schedule.routeInfo.disposalCompany.name }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>廃棄物名称</label>
              </div>
              <div class="c-inputWrap__items">
                {{ schedule.wasteInfo.wasteName ? schedule.wasteInfo.wasteName.name : "-" }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>数量</label>
              </div>
              <div class="c-inputWrap__items">
                {{ schedule.wasteInfo.wasteQuantity }} {{ schedule.wasteInfo.wasteUnitType ? schedule.wasteInfo.wasteUnitType.name : "-" }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>数量確定者</label>
              </div>
              <div class="c-inputWrap__items">
                {{ schedule.wasteInfo.quantityConfirmBusinessType ? schedule.wasteInfo.quantityConfirmBusinessType.name : "-" }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>荷姿</label>
              </div>
              <div class="c-inputWrap__items">
                {{ schedule.wasteInfo.shapeType ? schedule.wasteInfo.shapeType.name : "-" }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>荷姿数量</label>
              </div>
              <div class="c-inputWrap__items">
                {{ schedule.wasteInfo.shapeQuantity }}
              </div>
            </div>
            <div
              v-for="(remark, index) in schedule.remarksInfo"
              :key="'rmk' + (index + 1)"
              class="c-inputWrap"
            >
              <div class="c-inputWrap__label">
                <label>備考{{ index + 1 }}</label>
              </div>
              <div class="c-inputWrap__items">
                {{ remark.detail }}
              </div>
            </div>
          </div>
        </div>
        <!-- /.__scroll -->
      </div>

      <div class="c-modal__foot">
        <template v-if="isGenerator && schedule.manifestInfo.manifestStatusId === manifestStatus.RESERVE_PENDING">
          <div class="full-w">
            <ErrorMessage :errMsgs="errMsgs" />
            <div class="l-flex">
              <div class="l-flex cols u-space-btwn">
                <div class="c-radio">
                  <input
                    id="approve-true"
                    type="radio"
                    name="approve"
                    :value="true"
                    v-model="isApproved"
                  />
                  <label class="c-radio__label" for="approve-true">
                    <span class="c-radio__box"></span>
                    承認
                  </label>
                </div>
                <div class="c-radio">
                  <input
                    id="approve-false"
                    type="radio"
                    name="approve"
                    :value="false"
                    v-model="isApproved"
                  />
                  <label class="c-radio__label" for="approve-false">
                    <span class="c-radio__box"></span>
                    否認
                  </label>
                </div>
              </div>
              <div
                class="c-btn primary small l-flex__right"
                @click="approveHandler"
              >
                決定
              </div>
            </div>
          </div>
        </template>

        <template v-else-if="isGenerator || isCollectProxy">
          <div class="l-flex cols">
            <!-- 排出ログイン時、ログイン中のアカウントに紐づいたマニフェスト情報であれば、もしくは運搬代理登録であれば繰り返し解除/交付/編集/取消 表示可能 -->
            <!-- 運搬ログイン時、代理登録権限があれば繰り返し解除/交付/編集/取消 表示可能 -->
            <template v-if="(isGenerator) || (isCollectProxy && schedule.manifestInfo.proxyCollectMember)">
              <!-- 未予約ステータス かつ 繰り返し予約されたデータであれば、「繰り返し設定の解除」ボタンを表示 -->
              <div v-if="schedule.manifestInfo.isRecurring && schedule.manifestInfo.manifestStatusId === manifestStatus.NOT_RESERVE"
                class="c-btn secondary small" :class="{ disabled: isCallApiProcess }" @click="disableRecurring">
                繰り返し設定の解除
              </div>
              <!-- マニフェストステータスが0/10/11であれば「編集」ボタンを表示 -->
              <template v-if="isBeforeIssue">
                <router-link class="c-btn secondary small" :to="`/manifest/${this.schedule.manifestInfo.manifestId}`">
                  編集
                </router-link>
                <div class="c-btn secondary small" :class="{ disabled: schedule.manifestInfo.manifestStatusId === manifestStatus.UNDER_RESERVE }" @click="clickDeleteHandler">
                  取消
                </div>
              </template>
              <!-- マニフェストステータスが0/10/11以外は詳細ボタンのみ -->
              <template v-else>
                <router-link class="c-btn secondary small" :to="`/manifest/${this.schedule.manifestInfo.manifestId}`">
                  詳細
                </router-link>
              </template>
            </template>
            <!-- 上記条件に当てはまらない場合は詳細ボタンのみ -->
            <template v-else>
              <router-link class="c-btn secondary small" :to="`/manifest/${this.schedule.manifestInfo.manifestId}`">
                詳細
              </router-link>
            </template>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { API_URL, DOMAIN } from "@/const";
export default {
  props: {
    isShow: {
      type: Boolean,
    },
    isDeleteModalShow: {
      type: Boolean,
    },
    schedule: {
      type: Object,
    },
  },
  data() {
    return {
      isApproved: true, // manifestStatusIdが12のときのラジオボタン
      manifestStatus: DOMAIN.MANIFEST_STATUS,
      errMsgs: [],
      isGenerator: this.$store.getters["userInfo/isGenerator"],
      isCollectProxy: this.$store.getters["userInfo/isCollectProxy"],
      isCollector: this.$store.getters["userInfo/isCollector"],
      loginUser: this.$store.getters["userInfo/get"],
      isCallApiProcess: false,
    };
  },
  computed: {
    isAfterCollected() {
      const today = moment();
      const collectionDate = moment(this.schedule.manifestInfo.collectionDate);
      return collectionDate && today.isAfter(collectionDate);
    },
    isBeforeIssue() {
      return this.schedule.manifestInfo.manifestStatusId === DOMAIN.MANIFEST_STATUS.NOT_RESERVE
          || this.schedule.manifestInfo.manifestStatusId === DOMAIN.MANIFEST_STATUS.UNDER_RESERVE
          || this.schedule.manifestInfo.manifestStatusId === DOMAIN.MANIFEST_STATUS.RESERVED;
    },
  },
  methods: {
    closeModalHandler() {
      this.$emit("update:isShow", false);
    },
    clickDeleteHandler() {
      this.$emit("update:isDeleteModalShow", true);
    },
    disableRecurring() {
      axios
        .patch(API_URL.SCHEDULE.RECURRING + this.schedule.manifestInfo.manifestId)
        .then(() => {
          //リロード
          this.$router.go({
            path: this.$router.currentRoute.path,
            force: true,
          });
        })
        .catch((err) => {
          this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
        });
      this.$emit("update:isShow", false);
    },

    // 承認/否認の決定
    // 予約登録中-> 10、予約否認->19
    approveHandler() {
      axios.patch(API_URL.MANIFEST.STATUS_UPDATE, {
          manifestIdList: [
            { manifestId: this.schedule.manifestInfo.manifestId },
          ],
          manifestStatusId: this.isApproved ? DOMAIN.MANIFEST_STATUS.UNDER_RESERVE : DOMAIN.MANIFEST_STATUS.RESERVE_DENIAL,
        })
        .then(() => {
          // リロード
          this.$router.go({
            path: this.$router.currentRoute.path,
            force: true,
          });
        })
        .catch((err) => {
          this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.full-w {
  width: 100%;
}
</style>
