<template>
  <div class="l-wrapper loggedin">
    <AppHeader pageTitle="スケジュール" />
    <AppSideMenu />
    <main class="l-main">
      <div
        class="l-container"
        :class="{
          withSide: isSideShow,
        }"
      >
        <div class="l-calendar">
          <Calendar
            :useStoreList="useStoreList"
            :useStoreListAll="useStoreListAll"
            :useJwnetItemType="useJwnetItemType"
            :selectedStoreList="selectedStoreList"
            :selectedJwnetItemType="selectedJwnetItemType"
          />
        </div>

        <SideSortMenu
          v-show="isSideShow"
          :isStoreListSortModalShow.sync="isStoreListSortModalShow"
          :isJwnetItemSortModalShow.sync="isJwnetItemSortModalShow"
          :useStoreList="useStoreList"
          :useJwnetItemType="useJwnetItemType"
          :selectedStoreList.sync="selectedStoreList"
          :selectedJwnetItemType.sync="selectedJwnetItemType"
        />

        <div
          class="c-toggle"
          :class="{
            isOpen: isSideShow,
          }"
        >
          <div class="c-toggle__btn" @click="toggleSideHandler"></div>
        </div>
      </div>
    </main>

    <SortStoreListModal
      :isShow.sync="isStoreListSortModalShow"
      :storeList="storeList"
    />

    <SortJwnetItemTypeModal
      :isShow.sync="isJwnetItemSortModalShow"
      :jwnetItemTypes="jwnetItemType"
    />

    <AppFooter />
  </div>
</template>

<script>
import Calendar from "./components/Calendar";
import SideSortMenu from "./components/SideSortMenu";
import SortStoreListModal from "./components/SortStoreListModal";
import SortJwnetItemTypeModal from "./components/SortJwnetItemTypeModal";
import callApi from "./mixins/callApi";
import { LOCAL_STORAGE_INFO } from "@/const";

export default {
  components: {
    Calendar,
    SideSortMenu,
    SortStoreListModal,
    SortJwnetItemTypeModal,
  },
  mixins: [callApi],
  data() {
    return {
      storeList: [],
      useStoreListAll: [],
      useStoreList: [], // 5件まで
      jwnetItemType: [],
      useJwnetItemType: [], // 5件まで
      selectedStoreList: [],
      selectedJwnetItemType: [],
      profile: {},
      isSideShow: true,
      isStoreListSortModalShow: false,
      isJwnetItemSortModalShow: false,
    };
  },
  computed: {
    isGenerator() {
      return this.$store.getters["userInfo/isGenerator"];
    },
  },
  methods: {
    toggleSideHandler() {
      this.isSideShow = !this.isSideShow;
    },

    // 事業場選択肢 ５件取得 ※Cookieに保存されていたらそれを使用
    getUseStoreList() {
      if (this.isGenerator) {
        var localStorageContents = localStorage[LOCAL_STORAGE_INFO.USE_STORE_LIST];
        if (localStorageContents) {
          this.useStoreList = JSON.parse(localStorageContents);
        } else {
          this.storeList.forEach((list) => {
            list.generateStoreList.forEach((item) => {
              this.useStoreListAll.push(item);
              if (!localStorageContents && this.useStoreList.length < 5) {
                this.useStoreList.push(item);
              }
            });
          });
        }
      } else {
        // 運搬アカウントログイン時は全事業場を表示させる
        this.storeList.forEach((list) => {
          list.generateStoreList.forEach((item) => {
            this.useStoreListAll.push(item);
            this.useStoreList.push(item);
          });
        });
      }
    },

    // 廃棄物種類選択肢 を5件取得 ※Cookieに保存されていたらそれを使用
    getUseJwnetItem() {
      if(localStorage[LOCAL_STORAGE_INFO.USE_JWNET_ITEM_TYPE]) {
        this.useJwnetItemType = JSON.parse(
          localStorage[LOCAL_STORAGE_INFO.USE_JWNET_ITEM_TYPE]
        );
      } else {
        this.useJwnetItemType = this.jwnetItemType;
      }
    },

    // 事業場選択肢取得を選択状態にするため、
    initSelectedStoreList() {
      if (this.isGenerator) {
        if (localStorage[LOCAL_STORAGE_INFO.USE_STORE_LIST_CHECK]) {
          this.selectedStoreList = JSON.parse(
              localStorage[LOCAL_STORAGE_INFO.USE_STORE_LIST_CHECK]
          );
        } else {
          this.useStoreList.forEach((item) => {
            this.selectedStoreList.push(item.id);
          });
        }
      } else {
        
        this.useStoreList.forEach((item) => {
          let hasValue = this.selectedStoreList.includes(item.id);
          if (!hasValue) {
            this.selectedStoreList.push(item.id);
          }
        });
      }
    },

    // 廃棄物種類選択肢を選択状態にするため、
    initJwnetItemType() {
      if (localStorage[LOCAL_STORAGE_INFO.USE_JWNET_ITEM_TYPE_CHECK]) {
        this.selectedJwnetItemType = JSON.parse(
            localStorage[LOCAL_STORAGE_INFO.USE_JWNET_ITEM_TYPE_CHECK]
        );
      } else {
        this.useJwnetItemType.forEach((item) => {
          this.selectedJwnetItemType.push(item.id);
        });
      }
    },
  },

  mounted: function () {
    // プロフィール情報取得API
    this.getProfileApi();

    // 事業場選択肢取得
    this.getStoreListApi()
        .then(() => {
          this.getUseStoreList();
        })
        .then(() => {
          this.initSelectedStoreList();
        });

    // 委託契約に紐づく廃棄物種類選択肢取得API
    this.getEntrustJwnetItemsApi()
        .then(() => {
          this.getUseJwnetItem();
        })
        .then(() => {
          this.initJwnetItemType();
        });
  },
};
</script>
