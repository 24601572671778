import moment from "moment";

export default {
  computed: {
    calendars() {
      return this.getCalendar();
    },
    weekCalendars() {
      return (currentDate) => {
        return this.getWeekCalendar(currentDate);
      };
    },
  },
  methods: {
    getStartDate() {
      let date = moment(this.currentDate);
      date.startOf("month");
      const dateNum = date.day();
      return date.subtract(dateNum, "days");
    },
    getEndDate() {
      let date = moment(this.currentDate);
      date.endOf("month");
      const dateNum = date.day();
      return date.add(6 - dateNum, "days");
    },
    getCalendar() {
      let startDate = this.getStartDate();
      const endDate = this.getEndDate();
      const weekNumber = Math.ceil(endDate.diff(startDate, "days") / 7);
      let calendars = [];
      for (let week = 0; week < weekNumber; week++) {
        let weekRow = [];
        for (let day = 0; day < 7; day++) {
          weekRow.push({
            year: startDate.get("year"),
            month: startDate.get("month") + 1, // 月は0からカウントするため+1する
            date: startDate.get("date"),
            fullStr: `${startDate.get("year")}-${("0" + (startDate.get("month") + 1)).slice(-2)}-${("0" + (startDate.get("date"))).slice(-2)}`,
            full: startDate.toDate(),
          });
          startDate.add(1, "days");
        }
        calendars.push(weekRow);
      }
      return calendars;
    },
    getWeekCalendar(currentStartDate) {
      let startDate = this.getStartDate();
      const endDate = this.getEndDate();
      const weekNumber = Math.ceil(endDate.diff(startDate, "days") / 7);
      let calendars = [];
      let targetCount = 0;

      for (let week = 0; week < weekNumber; week++) {
        let weekRow = [];
        for (let day = 0; day < 7; day++) {
          weekRow.push({
            year: startDate.get("year"),
            month: startDate.get("month") + 1, // 月は0からカウントするため+1する
            date: startDate.get("date"),
            fullStr: `${startDate.get("year")}-${("0" + (startDate.get("month") + 1)).slice(-2)}-${("0" + (startDate.get("date"))).slice(-2)}`,
            full: startDate.toDate(),
          });
          startDate.add(1, "days");

          // 当月であれば、その週を表示
          if (
            moment(startDate).isSame(currentStartDate, "month") &&
            moment(startDate).isSame(currentStartDate, "day")
          ) {
            targetCount = week;
          }
        }
        calendars.push(weekRow);
      }
      return calendars[targetCount];
    },
  },
};
