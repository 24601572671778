<template>
  <div class="c-calendarSide">
    <div class="c-calendarSide__inner">
      <div class="c-calendarSide__lead">
        <h5 class="c-lead-s">絞り込み</h5>
      </div>
      <div v-if="isGenerator" class="c-calendarSide__each">
        <h6 class="c-calendarSide__each__lead">
          <span
            class="c-calendarSide__each__lead__icon"
            @click="clickStoreListSortShowHandler"
          ></span>
          <span>事業場</span>
        </h6>
        <div class="c-calendarSide__each__items">
          <template v-for="(item, index) in useStoreList">
            <div
              class="c-checkbox red"
              :class="{
                red: index === 0,
                green: index === 1,
                skyBlue: index === 2,
                orange: index === 3,
                purple: index === 4,
              }"
              :key="item.id"
            >
              <input
                :id="'store-cb-' + item.id"
                type="checkbox"
                :value="item.id"
                v-model="selectedStoreListData"
              />
              <label class="c-checkbox__label" :for="'store-cb-' + item.id">
                <span class="c-checkbox__box"></span>
                {{ dottedText(item.name, 8) }}
              </label>
            </div>
          </template>
        </div>
      </div>
      <div class="c-calendarSide__each">
        <h6 class="c-calendarSide__each__lead">
          <span
            class="c-calendarSide__each__lead__icon"
            @click="clickJwnetItemSortShowHandler"
          ></span>
          <span>廃棄物種類</span>
        </h6>
        <div class="c-calendarSide__each__items">
          <template v-for="item in useJwnetItemType">
            <div class="c-checkbox" :key="item.id">
              <input
                :id="'jwnet-cb-' + item.id"
                type="checkbox"
                :value="item.id"
                v-model="selectedJwnetItemTypeData"
              />
              <label class="c-checkbox__label" :for="'jwnet-cb-' + item.id">
                <span class="c-checkbox__box"></span>
                {{ dottedText(item.name, 8) }}
              </label>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import common from "@/mixin/common";
import { LOCAL_STORAGE_INFO } from "@/const";

export default {
  props: {
    useStoreList: {
      type: Array,
    },
    useJwnetItemType: {
      type: Array,
    },
    selectedStoreList: {
      type: Array,
    },
    selectedJwnetItemType: {
      type: Array,
    },
    isStoreListSortModalShow: {
      type: Boolean,
    },
    isJwnetItemSortModalShow: {
      type: Boolean,
    },
  },
  mixins: [common],
  data() {
    return {
      selectedStoreListData: [],
      selectedJwnetItemTypeData: [],
    };
  },
  computed: {
    isGenerator() {
      return this.$store.getters["userInfo/isGenerator"];
    },
  },
  watch: {
    selectedStoreListData() {
      this.$emit("update:selectedStoreList", this.selectedStoreListData);
      localStorage.setItem(
        LOCAL_STORAGE_INFO.USE_STORE_LIST_CHECK,
        JSON.stringify(this.selectedStoreListData)
      );
    },
    selectedJwnetItemTypeData() {
      this.$emit(
        "update:selectedJwnetItemType",
        this.selectedJwnetItemTypeData
      );
      localStorage.setItem(
        LOCAL_STORAGE_INFO.USE_JWNET_ITEM_TYPE_CHECK,
        JSON.stringify(this.selectedJwnetItemTypeData)        
      );
    },
    useStoreList() {
      if (!localStorage[LOCAL_STORAGE_INFO.USE_STORE_LIST_CHECK]) {
        this.selectedStoreListData = this.useStoreList.map(uSl => {
          return uSl.id
        })
      }
    },
    useJwnetItemType() {
      if (!localStorage[LOCAL_STORAGE_INFO.USE_JWNET_ITEM_TYPE_CHECK]) {
        this.selectedJwnetItemTypeData = this.useJwnetItemType.map(uSl => {
          return uSl.id
        })
      }
    }
  },
  methods: {
    clickStoreListSortShowHandler() {
      this.$emit("update:isStoreListSortModalShow", true);
    },
    clickJwnetItemSortShowHandler() {
      this.$emit("update:isJwnetItemSortModalShow", true);
    },
  },

  mounted() {
    // チェック状況を取得
    if (localStorage[LOCAL_STORAGE_INFO.USE_STORE_LIST_CHECK]) {
      this.selectedStoreListData = JSON.parse(
        localStorage[LOCAL_STORAGE_INFO.USE_STORE_LIST_CHECK]
      );
    }

    if (localStorage[LOCAL_STORAGE_INFO.USE_JWNET_ITEM_TYPE_CHECK]) {
      this.selectedJwnetItemTypeData = JSON.parse(
        localStorage[LOCAL_STORAGE_INFO.USE_JWNET_ITEM_TYPE_CHECK]
      );
    }
  },
};
</script>
<style lang="scss" scoped>
  .c-calendarSide__inner{
    padding-bottom: 5.8rem;
  }
</style>
