<template>
  <div v-if="isShow" class="c-modal lg">
    <div class="c-modal__overlay"></div>
    <div class="c-modal__content">
      <div class="c-modal__head">
        <h5 class="c-lead-s">廃棄物種類絞り込み</h5>
        <span class="c-modal__head__close" @click="closeModalHandler"></span>
      </div>
      <div class="c-modal__body">
        <div>
          <div class="c-modal__body__originalList">
            <div
              class="c-checkbox"
              v-for="itemType in jwnetItemTypes"
              :key="itemType.id"
            >
              <input
                :id="'itemtype-sort-cb-' + itemType.id"
                type="checkbox"
                :value="itemType"
                v-model="selectedItemType"
              />
              <label
                class="c-checkbox__label"
                :for="'itemtype-sort-cb-' + itemType.id"
              >
                <span class="c-checkbox__box"></span>
                {{ dottedText(itemType.name, 23) }}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="c-modal__foot">
        <div class="c-btn primary small" @click="clickSetHandler">設定</div>
      </div>
    </div>
  </div>
</template>

<script>
import common from "@/mixin/common";
import { LOCAL_STORAGE_INFO } from "@/const";

export default {
  props: {
    isShow: {
      type: Boolean,
    },
    jwnetItemTypes: {
      type: Array,
    },
  },
  mixins: [common],
  data() {
    return {
      selectedItemType: [],
    };
  },
  methods: {
    closeModalHandler() {
      this.$emit("update:isShow", false);
    },
    clickSetHandler() {
      localStorage.setItem(
        LOCAL_STORAGE_INFO.USE_JWNET_ITEM_TYPE,
        JSON.stringify(this.selectedItemType)
      );

      const selectedIds = this.selectedItemType.map(uSl => {
        return uSl.id
      })
      localStorage.setItem(LOCAL_STORAGE_INFO.USE_JWNET_ITEM_TYPE_CHECK, JSON.stringify(selectedIds));

      this.$router.go({ path: this.$router.currentRoute.path, force: true });
    },
  },
  watch: {
    isShow() {
      if (this.isShow) {
        if (localStorage[LOCAL_STORAGE_INFO.USE_JWNET_ITEM_TYPE]) {
          this.selectedItemType = JSON.parse(localStorage[LOCAL_STORAGE_INFO.USE_JWNET_ITEM_TYPE]);
        } else {
          this.selectedItemType = this.jwnetItemTypes;
        }
      }
    },
  },
};
</script>
