<template>
  <div class="c-calendarCtr">
    <div class="c-calendarCtr__left">
      <div class="c-btn secondary mini" @click="currentDateHandler">今日</div>
      <div class="c-calendarCtr__left__date">
        <div class="c-arrowBtn back" @click="prevMonthHandler"></div>
        <h3 v-if="isMonthShow" class="c-lead-s">
          {{ currentDate | dateJpYMFormat }}
        </h3>
        <h3 v-if="!isMonthShow && weeks" class="c-lead-s">
          {{ weeks[0].fullStr | dateJpMDFormat }} 〜
          {{ weeks[weeks.length - 1].fullStr | dateJpMDFormat }}
        </h3>
        <div class="c-arrowBtn" @click="nextMonthHandler"></div>
      </div>
    </div>
    <div class="c-calendarCtr__right">
      <div class="c-btn secondary mini" @click="settingHandler">
        カレンダー設定
      </div>
      <div class="c-tabs">
        <div
          class="c-tabs__each"
          :class="{
            active: isMonthShow,
          }"
          @click="changeDisplay(true)"
        >
          月
        </div>
        <div
          class="c-tabs__each"
          :class="{
            active: !isMonthShow,
          }"
          @click="changeDisplay(false)"
        >
          週
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

import { COOKIE_INFO } from "@/const";

export default {
  props: {
    currentDate: {
      type: Object,
    },
    isMonthShow: {
      type: Boolean,
    },
    isSettingShow: {
      type: Boolean,
    },
    weeks: {
      type: Array,
    },
  },
  methods: {
    nextMonthHandler() {
      if (this.isMonthShow) {
        const newCurrentDate = moment(this.currentDate).add(1, "month");
        this.$emit("update:currentDate", newCurrentDate);
      } else {
        const newCurrentDate = moment(this.currentDate).add(7, "days");
        this.$emit("update:currentDate", newCurrentDate);
      }
    },
    prevMonthHandler() {
      if (this.isMonthShow) {
        const newCurrentDate = moment(this.currentDate).subtract(1, "month");
        this.$emit("update:currentDate", newCurrentDate);
      } else {
        const newCurrentDate = moment(this.currentDate).subtract(7, "days");
        this.$emit("update:currentDate", newCurrentDate);
      }
    },
    currentDateHandler() {
      const newCurrentDate = moment();
      this.$emit("update:currentDate", newCurrentDate);
    },
    changeDisplay(status) {
      this.$emit("update:isMonthShow", status);
      this.$cookies.set(COOKIE_INFO.SHOW_MONTH, status);
    },
    settingHandler() {
      this.$emit("update:isSettingShow", true);
    },
  },
};
</script>
