<template>
  <div class="c-calendar">
    <CalendarController
      :currentDate.sync="currentDate"
      :isMonthShow.sync="isMonthShow"
      :weeks="weekCalendars(currentDate)"
      :isSettingShow.sync="isSettingShow"
    />
    <CalendarMonth
      v-if="isMonthShow"
      :reserves="reserves"
      :currentDate="currentDate"
      :reserveClick="clickScheduleHandler"
      :useStoreList="useStoreList"
      :selectedStoreList="selectedStoreList"
      :selectedJwnetItemType="selectedJwnetItemType"
    />
    <CalendarWeek
      v-else
      :reserves="reserves"
      :currentDate="currentDate"
      :reserveClick="clickScheduleHandler"
      :isPerHour="isPerHour"
      :startHourPos="startHourPos"
      :useStoreList="useStoreList"
      :selectedStoreList="selectedStoreList"
      :selectedJwnetItemType="selectedJwnetItemType"
    />

    <!-- 設定モーダル -->
    <SettingModal
      :isShow.sync="isSettingShow"
      :isPerHour="isPerHour"
      :startHourPos="startHourPos"
    />
    <!-- 詳細モーダル -->
    <DetailModal
      :schedule.sync="selectedSchedule"
      :isShow.sync="isDetailModalShow"
      :isDeleteModalShow.sync="isDeleteModalShow"
    />
    <!-- 削除確認モーダル -->
    <DeleteConfirmModal
      :isShow.sync="isDeleteModalShow"
      :isDetailModalShow.sync="isDetailModalShow"
      @delete="clickDeleteHandler"
    />
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import CalendarController from "./CalendarController";
import CalendarMonth from "./CalendarMonth";
import CalendarWeek from "./CalendarWeek";
import SettingModal from "./SettingModal";
import DetailModal from "./DetailModal";
import callApi from "../mixins/callApi";
import DeleteConfirmModal from "./DeleteConfirmModal";
import detailHandler from "../mixins/detailHandler";
import generateCalendar from "../mixins/generateCalendar";

import { API_URL, COOKIE_INFO } from "@/const";

export default {
  components: {
    CalendarController,
    CalendarMonth,
    CalendarWeek,
    SettingModal,
    DetailModal,
    DeleteConfirmModal,
  },
  mixins: [callApi, detailHandler, generateCalendar],
  props: {
    useStoreList: {
      type: Array,
    },
    useStoreListAll: {
      type: Array,
    },
    useJwnetItemType: {
      type: Array,
    },
    selectedStoreList: {
      type: Array,
    },
    selectedJwnetItemType: {
      type: Array,
    },
  },
  data() {
    return {
      schedules: [],
      isMonthShow: true,
      isSettingShow: false,
      currentDate: moment(),
      reserves: {},
      isDetailModalShow: false,
      isDeleteModalShow: false,
      selectedSchedule: null,
      isPerHour: true,
      startHourPos: 0,
    };
  },
  watch: {
    currentDate: {
      handler: function (after, before) {
        if (before.month() !== after.month()) {
          this.getSchedulesForThreeMonth();
        }
        this.$cookies.set(COOKIE_INFO.SCHEDULE_LIST_DATE, JSON.stringify(this.currentDate));
      },
    },
    schedules: function () {
      this.formatReserves();
    },
  },
  computed: {
    getSchedules() {
      return this.schedules;
    },
  },
  methods: {
    handleCookies() {
      // 週間月間切り替え
      if (this.$cookies.isKey(COOKIE_INFO.SHOW_MONTH)) {
        if (this.$cookies.get(COOKIE_INFO.SHOW_MONTH) === "true") {
          this.isMonthShow = true;
        } else {
          this.isMonthShow = false;
        }
      } else {
        this.isMonthShow = true;
      }
      // 表示時間位置
      if (this.$cookies.isKey(COOKIE_INFO.SHOW_HOUR)) {
        this.startHourPos = +this.$cookies.get(COOKIE_INFO.SHOW_HOUR);
      } else {
        this.startHourPos = 0;
      }
      // 1時間毎か３０分毎か
      if (this.$cookies.isKey(COOKIE_INFO.PER_HOUR)) {
        if (this.$cookies.get(COOKIE_INFO.PER_HOUR) === "true") {
          this.isPerHour = true;
        } else {
          this.isPerHour = false;
        }
      } else {
        this.isPerHour = true;
      }

      // 日付
      if (this.$cookies.isKey(COOKIE_INFO.SCHEDULE_LIST_DATE)) {
        const cookieDate = moment(JSON.parse(this.$cookies.get(COOKIE_INFO.SCHEDULE_LIST_DATE)));

        if (this.currentDate.month() === cookieDate.month()) {
          // クッキーの日付と現在日時の月が同じ場合、watch関数でスケジュール取得APIが実行されない
          // mountedでの初期実行の代わりにここで実行
          this.getSchedulesForThreeMonth();
        } else {
          // クッキーの日付と現在日時の月が異なるので、watch関数でスケジュール取得APIが実行される
          this.currentDate = cookieDate;
        }
      } else {
        // mountedでの初期実行の代わりにここで実行
        this.getSchedulesForThreeMonth();
      }

    },

    // スケジュール一覧取得 (前月、次月も取得なので3回取得する)
    getSchedulesForThreeMonth() {
      const currentMonthDate = this.currentDate;
      const lastMonthDate = moment(this.currentDate).subtract(1, 'months');
      const nextMonthDate = moment(this.currentDate).add(1, 'months');
      axios.all([axios.get(`${API_URL.SCHEDULE.LIST}?targetYear=${lastMonthDate.year()}&targetMonth=${lastMonthDate.month() + 1}`),
           axios.get(`${API_URL.SCHEDULE.LIST}?targetYear=${currentMonthDate.year()}&targetMonth=${currentMonthDate.month() + 1}`),
           axios.get(`${API_URL.SCHEDULE.LIST}?targetYear=${nextMonthDate.year()}&targetMonth=${nextMonthDate.month() + 1}`)])
     .then(axios.spread((res1, res2, res3) => {
      // 3月=>4月=>5月の速変更の場合、(3月,4月,5月)分のスケジュールAPIの並列実行と(4月,5月,6月)分のスケジュールAPIの並列実行
      // (4月,5月,6月)分のみでスケジュールを更新する
      if (currentMonthDate.isSame(this.currentDate)) {
        this.schedules = [];
        this.schedules = [...res1.data.scheduleList, ...res2.data.scheduleList, ...res3.data.scheduleList]
      }
     }))
     .catch(error => console.log(error));
    },

    // Calendarに表示しやすいようにデータフォーマットする
    formatReserves() {
      this.reserves = {};
      this.schedules.map((schedule) => {
        schedule.scheduleInfoList.map((info) => {
          let newInfo = info;
          newInfo.generateStoreId = schedule.generateStoreId;
          const formatteddate = moment(info.collectionDate, "YYYYMMDD").format(
            "YYYY-MM-DD"
          );
          if (!this.reserves[formatteddate]) {
            this.reserves[formatteddate] = [newInfo];
          } else {
            this.reserves[formatteddate] = [
              ...this.reserves[formatteddate],
              newInfo,
            ];
          }
        });
      });
    },

    // スケジュール押下後にAPI呼び出して詳細モーダル展開  API_URL.
    clickScheduleHandler(manifestId) {
      axios
        .get(API_URL.SCHEDULE.DETAIL + manifestId)
        .then((res) => {
          this.selectedSchedule = res.data;
        })
        .then(() => {
          this.isDetailModalShow = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },

  mounted() {
    this.handleCookies();
  },
};
</script>
