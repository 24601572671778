<template>
  <div
    class="c-calendar__popup"
    :class="{
      right: cellIndex === 6,
    }"
    @click.stop="
      () => {
        return;
      }
    "
  >
    <div class="c-calendar__popup__head">
      <div v-if="isMonth" class="c-calendar__popup__head__date">
        {{ day.fullStr | dateJpMDFormat }}
      </div>
      <div v-else class="c-calendar__popup__head__date">
        {{ day.fullStr | dateJpMDFormat }}
      </div>

      <router-link
        v-if="isGenerator || isCollectProxy"
        :to="isGenerator ? `/manifest/input?date=${day.fullStr}` : `/manifest/proxy/input?date=${day.fullStr}`"
        class="c-calendar__popup__head__plus"
      ></router-link>
    </div>
    <slot></slot>
  </div>
  <!-- /__popup -->
</template>

<script>
import timeManage from "../mixins/timeManage";

export default {
  mixins: [timeManage],
  props: {
    cellIndex: {
      type: Number,
    },
    day: {
      type: Object,
    },
    isPerHour: {
      // for Week
      type: Boolean,
    },
    isMonth: {
      type: Boolean,
    },
  },
  data() {
    return {
      isGenerator: this.$store.getters["userInfo/isGenerator"],
      isCollectProxy: this.$store.getters["userInfo/isCollectProxy"],
    };
  },
};
</script>
